/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchElements = /* GraphQL */ `
  query SearchElements(
    $filter: SearchableElementFilterInput
    $sort: [SearchableElementSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableElementAggregationInput]
  ) {
    searchElements(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        bibID
        name
        description
        type
        textData
        Bib {
          id
          name
          imageUrl
          description
          createdAt
          updatedAt
        }
        quotes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getBib = /* GraphQL */ `
  query GetBib($id: ID!) {
    getBib(id: $id) {
      id
      name
      imageUrl
      description
      elements {
        items {
          id
          bibID
          name
          description
          type
          textData
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBibs = /* GraphQL */ `
  query ListBibs(
    $filter: ModelBibFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBibs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        imageUrl
        description
        elements {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getElement = /* GraphQL */ `
  query GetElement($id: ID!) {
    getElement(id: $id) {
      id
      bibID
      name
      description
      type
      textData
      Bib {
        id
        name
        imageUrl
        description
        elements {
          nextToken
        }
        createdAt
        updatedAt
      }
      quotes {
        items {
          id
          elementID
          description
          quoteText
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listElements = /* GraphQL */ `
  query ListElements(
    $filter: ModelElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listElements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bibID
        name
        description
        type
        textData
        Bib {
          id
          name
          imageUrl
          description
          createdAt
          updatedAt
        }
        quotes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuote = /* GraphQL */ `
  query GetQuote($id: ID!) {
    getQuote(id: $id) {
      id
      elementID
      description
      quoteText
      element {
        id
        bibID
        name
        description
        type
        textData
        Bib {
          id
          name
          imageUrl
          description
          createdAt
          updatedAt
        }
        quotes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuotes = /* GraphQL */ `
  query ListQuotes(
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        elementID
        description
        quoteText
        element {
          id
          bibID
          name
          description
          type
          textData
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const elementsByBibID = /* GraphQL */ `
  query ElementsByBibID(
    $bibID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelElementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    elementsByBibID(
      bibID: $bibID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bibID
        name
        description
        type
        textData
        Bib {
          id
          name
          imageUrl
          description
          createdAt
          updatedAt
        }
        quotes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const quotesByElementID = /* GraphQL */ `
  query QuotesByElementID(
    $elementID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    quotesByElementID(
      elementID: $elementID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elementID
        description
        quoteText
        element {
          id
          bibID
          name
          description
          type
          textData
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
