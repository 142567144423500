import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardMedia } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';



const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function BasicCard({bib,  deleteOnClick}) {
  const navigate = useNavigate();
  return (
    <Card sx={{ 
      height: "100%",
      display: "flex",
      flexDirection: "column",
      minWidth: 200, maxWidth: 220, maxHeight: 270, height: 400,
      cursor: "pointer"}} >
      <CardContent>
      <CardMedia onClick={() => navigate('/BibElementView', { state: {bib}})}
        component="img"
        height="130px"
        width="220px"
        image={bib.imageUrl}
        alt="bib alt img"
      />
        <Typography variant="h5" component="div" onClick={() => navigate('/BibElementView', { state: {bib}})}>
          {bib.name}
        </Typography>
      </CardContent>
      <CardActions  sx={{ mt: "auto" }}>
        <Button onClick={() => deleteOnClick(bib.id)}
         variant="outlined" color="error" endIcon={<DeleteIcon />}> Delete
        </Button>
        <Button onClick={() => navigate('/CreateElement', { state: {bib}})} variant="outlined" size="medium">Add element</Button>
      </CardActions>
    </Card>
  );
}