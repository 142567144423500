//App.js
import { Authenticator } from '@aws-amplify/ui-react';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import Bibboard from './pages/Bibboard';
import CreateElement from './pages/CreateElement';
import CreateBib from './pages/CreateBib';
import { Auth } from 'aws-amplify';

import React, { useState, useEffect } from 'react'
import ViewQuotes from './pages/ViewQuotes';
import TextSearch from './pages/TextSearch';
import BibElementView from './pages/BibElementView';
import ElementView from './pages/ElementView';

import './App.css';


function MyRoutes() {
  const [userEmail, initUserEmail] = useState({})

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        initUserEmail(res.attributes.email)
      })
      .catch((e) => {
        console.log(e.message)
      })
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        
          <Route index 
          path="/"
          element={
            <RequireAuth>
              <Bibboard email={userEmail}/>
            </RequireAuth>
          } />
          <Route
            path="/CreateBib"
            element={
              <RequireAuth>
                <CreateBib email={userEmail} ></CreateBib>
              </RequireAuth>
            }
          />
          <Route
            path="/CreateElement"
            element={
              <RequireAuth>
                <CreateElement email={userEmail}/>
              </RequireAuth>
            }
          />
           <Route
            path="/BibElementView"
            element={
              <RequireAuth>
                <BibElementView email={userEmail}/>
              </RequireAuth>
            }
          />
           <Route
            path="/ElementView"
            element={
              <RequireAuth>
                <ElementView email={userEmail}/>
              </RequireAuth>
            }
          />



          <Route
            path="/ViewQuotes"
            element={
              <RequireAuth>
                <ViewQuotes email={userEmail}/>
              </RequireAuth>
            }
          />
           <Route
            path="/TextSearch"
            element={
              <RequireAuth>
                <TextSearch email={userEmail}/>
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
        
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;