import React, { useState, useEffect  } from 'react'
import Card from './ElementCard.js';

import { API } from "aws-amplify";
import { elementsByBibID } from "../../graphql/queries";
import { deleteElement as deleteElementMutation,
      } from "../../graphql/mutations";

function Elements ({bibDetails}) {
    const [elements, setElements] = useState([]);


    useEffect(() => {
        fetchElements()
      }, [])
    
   
  
       
    async function fetchElements() {
   
     var result = await API.graphql({
       query: elementsByBibID,
       variables: {bibID: bibDetails.id},
     });
    
    setElements(result.data.elementsByBibID.items);
  }

  async function deleteElement(id) {
    const newElements= elements.filter((element) => element.id !== id);
    setElements(newElements);
    await API.graphql({
      query: deleteElementMutation,
      variables: { input: { id } },
    });
  }
  

    return (

      <div className='element-container'>
       {elements.map((element) => (
        <div className='element-container-child'  key={element.id || element.name} >
        <Card element={element}
             deleteOnClick={deleteElement}>

             </Card>
        </div>))} 
      </div>
    )
}

export default  Elements;