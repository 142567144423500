import React, { useState, useEffect } from 'react'
import Navbar from "../components/navbar/Navbar"
import Sidebar from "../components/mainpage/Sidebar";

  import {
    createQuote as createQuoteMutation
  } from "../graphql/mutations";
  import { API } from "aws-amplify";
import { listQuotes } from "../graphql/queries";

import { useLocation } from 'react-router';
import { Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function ElementView ({email}) {

  const {state} = useLocation();
  const { element } = state; // Read values passed on state


  async function createQuote(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      elementID: element.id,
      description: form.get("description"),
      quoteText: form.get("quoteText"),
    };
    await API.graphql({
      query: createQuoteMutation,
      variables: { input: data },
    });

    event.target.reset();
  }

    return (
    <div>
     <div className="NavAndTitle">
      <div className="NavBar">
         <Navbar titleName={{name: 'View Element'}}
                 userEmail={email}>
         </Navbar>
      </div>
     </div>
     <div className="SideBarAndContentViewElement">
      <Sidebar></Sidebar>

     <Box>
      <Card sx={{ minWidth: 300, maxWidth: 600, width: 600}} >
      <CardContent>
        <Typography variant="h6" component="div" >
          {element.name}
        </Typography>
        <Typography variant="h6">
           {element.description}
        </Typography>
        <Typography variant="body1">
           {element.textData}
        </Typography>
      </CardContent>
    </Card>
    </Box> 
    <div >
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={createQuote}>
      <div className="CreateBibFormBox">
      <TextField
              name="elementId"
              label={element.name}
              
              disabled
              
             
          
        />
        <TextField
              name="description"    
              label="Description"
              required
        />
        <TextField
              name="quoteText"
              label="Quote Text"             
              required
        />
        <Button  variant="outlined" size="medium"  type="submit">Create Quote</Button>
      </div>
    </Box>
    </div> 

       
     </div>
     </div>
    )
}

export default ElementView