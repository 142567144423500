import logo from '../../img/b-letter-logo.png';
import Logout from "./Logout.js"


function Navbar({titleName, userEmail}) {
  //console.log(userEmail);
    return (
      <div className='nav-border-bottom'>
       <div className="nav-container">
         <div><img src={logo}alt="Logo" className='logo-left'/>Biblaridion</div>
         <div className='PageName'>{titleName.name}</div>
         <Logout email={userEmail}/>
     </div>
    </div>
    )
  }
  
export default Navbar;