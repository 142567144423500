import React, { useState, useEffect } from 'react'
import Navbar from "../components/navbar/Navbar"
import Sidebar from "../components/mainpage/Sidebar";
import Bibs from "../components/mainpage/Bibs";
import {  API, graphqlOperation } from 'aws-amplify'
import { listBibs } from '../graphql/queries'
import { Auth } from 'aws-amplify';


function Bibboard ({email}) {
    //console.log(email);
  const [bibs, setBibs] = useState([])

  useEffect(() => {
    fetchBibs()
  }, [])


  async function fetchBibs() {
    try {
      const bibData = await API.graphql(graphqlOperation(listBibs))
      const bibs = bibData.data.listBibs.items
      setBibs(bibs)
    } catch (err) { 
        console.log(err.errors[0].errorType);
        console.log(err.errors[0].message);
        console.log('error fetching bibs') 
    }
  }



    return (
    <div className='page'>
     <div className="NavAndTitle">
      <div className="NavBar">
         <Navbar titleName={{name: 'Bibboard'}}
                 userEmail= {email}>
         </Navbar>
      </div>
     </div>
     <div className="SideBarAndContent">
      <Sidebar sentIndex={0}></Sidebar>
      <Bibs></Bibs>
     </div>
     </div>
    )
}

export default  Bibboard;