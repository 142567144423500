import React, { useState, useEffect} from "react";
import Navbar from "../components/navbar/Navbar"
import Sidebar from "../components/mainpage/Sidebar";


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {  API, graphqlOperation } from "aws-amplify";
import { searchElements } from "../graphql/queries";
import {
    Flex,

    Text,
    View,
    HighlightMatch,
    Heading,
  } from "@aws-amplify/ui-react";

function TextSearch ({email}) {

  const [texts, setTexts] = useState([]);
  const [queryText, setQueryText] = useState([]);

  function onChangeSetQueryText(event) {
    event.preventDefault();  
    setQueryText(event.target.value)
   }

  useEffect(() => {
    textSearch();
  }, []);


  async function textSearch(event) {
    if (event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const searchText = form.get("textSearch");

  

    const apiData = await API.graphql(
      graphqlOperation(searchElements, { filter: {textData: {match: searchText} } }));
      console.log("this is api data", apiData);
    const textsFromAPI = apiData.data.searchElements.items;
    setTexts(textsFromAPI);
  }
  }

    return (
    <div className="p0">
     <div className="NavAndTitle">
      <div className="NavBar">
         <Navbar titleName={{name: 'Text Search'}}
                 userEmail={email}>
         </Navbar>
      </div>
     </div>
     <div className="SideBarAndContent">
      <Sidebar sentIndex={3}></Sidebar>
      <div className="text-search-box-and-result">
      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={textSearch}>
      <div style={{display:"flex", flexDirection: "row", padding: "10px"}}>
      <TextField
            name="textSearch"
            placeholder="Text Search"
            label="Text Search"
            variation="quiet"
            required
            onChange={onChangeSetQueryText}
        />
        <Button sx={{width: "200px"}} variant="outlined" size="medium"  type="submit">Search</Button>
      </div>
    </Box>



      <div className='search-container'>
       {texts.map((text) => (
        <div className='text-container-child'   key={text.id || text.name} >
          <Card style={{padding: "10px", margin: "10px", maxWidth: "400px"}}>
          <Heading level={5}>
            {text.name}
            </Heading>
            
            
            <Text as="span">
            <HighlightMatch query={queryText}>
            {text.description}
            </HighlightMatch>
            </Text>
            <Text as="p">
            <HighlightMatch query={queryText}>
            {text.textData}
            </HighlightMatch>
            </Text>
          </Card>
        </div>))} 
      </div>
     </div>
     </div>
     </div>
    )
}

export default  TextSearch;