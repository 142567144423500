import React, { useState, useEffect } from 'react'
import Navbar from "../components/navbar/Navbar"
import Title from "../components/mainpage/Title"
import Sidebar from "../components/mainpage/Sidebar";
import Elements from "../components/mainpage/Elements"

import { useLocation } from 'react-router';


function BibElementView ({email}) {

  const {state} = useLocation();
  const { bib } = state; // Read values passed on state
  

    return (
    <div>
     <div className="NavAndTitle">
      <div className="NavBar">
         <Navbar titleName={{name: 'View Element'}}
                 userEmail={email}>
         </Navbar>
      </div>
     </div>
     <div className="SideBarAndContent">
      <Sidebar></Sidebar>
       <Elements bibDetails={bib}></Elements>
     </div>
     </div>
    )
}

export default BibElementView