import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar/Navbar"
import Sidebar from "../components/mainpage/Sidebar";
import CreateBibForm from "../components/mainpage/CreateBibForm";




import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import {
  Button,
  Flex,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import { listBibs } from "../graphql/queries";
import {
  createBib as createBibMutation,
} from "../graphql/mutations";




function CreateBib ({email}) {

  const [bibs, setBibs] = useState([]);
  
 

  useEffect(() => {
    fetchBibs();
  }, []);

  async function fetchBibs() {
    const apiData = await API.graphql({ query: listBibs });
    const bibsFromAPI = apiData.data.listBibs.items;
    setBibs(bibsFromAPI);
  }

  async function createBib(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    console.log("this is event form?", form);
    const data = {
      name: form.get("name"),
      imageUrl: form.get("upload"),
      description: form.get("description"),
    };
    await API.graphql({
      query: createBibMutation,
      variables: { input: data },
    });
    fetchBibs();
    event.target.reset();
  }


    return (
    <div>
     <div className="NavAndTitle">
      <div className="NavBar">
         <Navbar titleName={{name: 'Create Biblaridion'}}
                 userEmail={email}>
         </Navbar>
      </div>
     </div>
     <div className="SideBarAndContentCreateBib">
      <Sidebar sentIndex={1}></Sidebar>

      <div  style={{paddingLeft: "300px"}}>
       <CreateBibForm createBib={createBib} ></CreateBibForm>

      </div>
     </div>
     </div>
    )
}

export default  CreateBib;