import React from 'react'

import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, Heading, View } from '@aws-amplify/ui-react';
import userPic from '../../img/blank-profile-picture.svg';

function Logout ({email}) {
    
    //console.log("user is: " + email);
    let userStr = ""+email;
    let userparts = userStr.split("@");
    let username = userparts[0];

    const { route, signOut } = useAuthenticator((context) => [
      context.route,
      context.signOut,
    ]);
    const navigate = useNavigate();
  
    function logOut() {
      signOut();
      navigate('/login');
    }


    return (
      <div className='logoutText'>
        <div onClick={() => logOut()} >
          {username} <img src={userPic} alt="userPic" className='userPic'/></div>
        </div>
    )
}

export default Logout;