import React, { useState, useEffect } from "react";
import Card from './QuoteCard.js';

import { API } from "aws-amplify";
import { listQuotes } from "../../graphql/queries";
import { deleteQuote as deleteQuoteMutation,
} from "../../graphql/mutations";


function Quotes () {
    const [quotes, setQuotes] = useState([]);

    useEffect(() => {
      deleteQuote("b38d6f19-fe7b-4f2a-a218-e2b8ed27e4de");
      fetchQuotes();
    }, []);
  
    async function fetchQuotes() {
      const apiData = await API.graphql({ query: listQuotes });
      const QuotesFromAPI = apiData.data.listQuotes.items;
      setQuotes(QuotesFromAPI);
   
    }
  
    async function deleteQuote(id) {
        const newQuotes= quotes.filter((quote) => quote.id !== id);
        setQuotes(newQuotes);
        await API.graphql({
          query: deleteQuoteMutation,
          variables: { input: { id } },
        });
      }
    return (

      <div className='quote-container'>
       {quotes.map((q) => (
        <div className='quote-container-child'              key={q.id || q.name} >
        <Card quote={q}
             deleteOnClick={deleteQuote}>

             </Card>
        </div>))} 
      </div>
    )
}

export default Quotes;