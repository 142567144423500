import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar/Navbar"
import Sidebar from "../components/mainpage/Sidebar";
import Quotes from "../components/mainpage/Quotes"


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function ViewQuotes ({email}) {

    function downloadQuote(event) {
      event.preventDefault();
      console.log("Selected quote to download");

    }

    return (
    <div>
     <div className="NavAndTitle">
      <div className="NavBar">
         <Navbar titleName={{name: 'View Element Quotes'}}
                 userEmail={email}>
         </Navbar>
      </div>
     </div>
     <div className="SideBarAndContentViewQuotes">
      <Sidebar sentIndex={2}></Sidebar>
        <Quotes></Quotes>
        <div className="download-quote-form" >
        <Box component="form"  onSubmit={downloadQuote}>
            <Button type="submit" variation="primary">
              Download Quotes
            </Button>
        </Box>
        </div>
     </div>
     </div>
    )
}

export default  ViewQuotes;