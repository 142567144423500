import React, { useState} from "react";
import Navbar from "../components/navbar/Navbar"
import Title from "../components/mainpage/Title"
import Sidebar from "../components/mainpage/Sidebar";
import { useLocation } from 'react-router-dom';

import "@aws-amplify/ui-react/styles.css";
import { API, Storage} from "aws-amplify";
import {
  
  Flex,
  TextAreaField,
  
  View,
} from "@aws-amplify/ui-react";

import {
  createElement as createElementMutation,
} from "../graphql/mutations";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardMedia } from '@mui/material';

function CreateElement ({email}) {
  const {state} = useLocation();
  const { bib } = state; // Read values passed on state
  
  const [uFile, setFile] = useState([]);

  async function uploadFile() {
    console.log("inside of onChange file upload3")

    try {
      const result = await Storage.put(uFile.name, "Private Content", {
        level: "private",
        contentType: "text/plain",
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
   
  function setFileName(e) {
    const file = e.target.files[0];
    console.log("this is file", file);
    setFile(file);
  }


    async function createElement(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
          name: form.get("name"),
          description: form.get("description"),
          type: "Text Type",
          textData: form.get("textData"),
          bibID: bib.id
        };
        console.log("this is the data to send", data)
        var result = await API.graphql({
          query: createElementMutation,
          variables: { input: data },
        });

        if (uFile.name) {
          uploadFile();
        }
      
        event.target.reset();
      }
    

    return (
    <div>
     <div className="NavAndTitle">
      <div className="NavBar">
         <Navbar titleName={{name: 'Create Element'}}
                 userEmail={email}>
         </Navbar>
      </div>
     </div>
     <div className="SideBarAndContentCreateElement">
      <Sidebar></Sidebar>
      <div className="" >
      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        
      }}
      noValidate
      autoComplete="off"
      onSubmit={createElement}>
      <div className="createElementForm">
      <TextField
                name="name"
                placeholder="Element name"
                label="Element name"
                required
               />
      <TextField
              name="description"
              placeholder="Element Description"
              label="Element Description"
              required
               />
      <TextField
               name="textType"
               placeholder="Text Type"
               label= "Element Type"
               disabled
               />
      <TextField
          id="name"
          label="Parent Bib"
          name="name"         
          defaultValue={bib.name}
          disabled
        />
         <input type="file" onChange={setFileName}/>
         <TextField
          required
          id="description"
          multiline
          rows={4}
          label="Text data for element"
          name="textData"
        />
        <Button
        sx={{width: "240px"}}
          variant="outlined" size="medium"  type="submit">Create Element</Button>
      </div>
    </Box>

  </div>
     
     </div>
     </div>
    )
}

export default  CreateElement;