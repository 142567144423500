import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import CreateBibIcon from '@mui/icons-material/Dataset';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {  useNavigate } from 'react-router-dom';
import * as React from 'react';

function Sidebar({sentIndex}) {
  const [selectedIndex, setSelectedIndex] = React.useState(sentIndex);
  const navigate = useNavigate();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };


    return (
    <div className="main-side-container">
      <Box sx={{ p: 2, border: '1px thin grey', backgroundColor: '#white' }}>
        <List
                sx={{
                  // selected and (selected + hover) states
                  '&& .Mui-selected, && .Mui-selected:hover': {
                    bgcolor: '#e8f0fe',
                    '&, & .MuiListItemIcon-root': {
                      color: '#185ac6',
                    },
                  },
                  // hover states
                  '& .MuiListItemButton-root:hover': {
                    bgcolor: '#e8f0fe',
                    '&, & .MuiListItemIcon-root': {
                      color: '#185ac6',
                    },
                  },
                }}
        >
          <ListItem disablePadding={true}
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}>
            <ListItemButton onClick={() => navigate('/')}>
              <ListItemIcon><HomeIcon sx={{ fontSize: "2.3rem" }}></HomeIcon></ListItemIcon>
              <ListItemText>Bibboard</ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider></Divider>
          <ListItem disablePadding={true}
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}>
            <ListItemButton onClick={() => navigate('/CreateBib')}>
              <ListItemIcon><CreateBibIcon sx={{ fontSize: "2.3rem" }}></CreateBibIcon></ListItemIcon>
              <ListItemText>Create Bib</ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider></Divider>
          <ListItem disablePadding={true}
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}>
            <ListItemButton onClick={() => navigate('/ViewQuotes')}>
              <ListItemIcon><FormatQuoteIcon sx={{ fontSize: "2.3rem" }}></FormatQuoteIcon></ListItemIcon>
              <ListItemText>View Quotes</ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider></Divider>
          <ListItem disablePadding={true}
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}>
            <ListItemButton onClick={() => navigate('/TextSearch')}>
              <ListItemIcon><ManageSearchIcon sx={{ fontSize: "2.3rem" }}></ManageSearchIcon></ListItemIcon>
              <ListItemText>Text Search</ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider></Divider>
       </List>
       </Box>  
   
    </div>
    )
  }
  
export default Sidebar;