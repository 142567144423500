import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';



const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function ElementCard({element,  deleteOnClick}) {
  const navigate = useNavigate();
  
  return (
   
   <Card sx={{ 
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minWidth: 200, maxWidth: 220, maxHeight: 220, height: 220
    ,cursor: "pointer"}} 
    >
      <CardContent onClick={() => navigate('/ElementView', { state: {element}})}>
      <Typography variant="body1" component="div" >
            {element.name}
      </Typography>
        <Divider></Divider>
        <Divider></Divider>
        <Typography variant="body2">
           {element.description}
        </Typography>
      </CardContent>
      <CardActions sx={{mt: "auto"}}>

        <Button onClick={() => deleteOnClick(element.id)}
         variant="outlined" color="error" endIcon={<DeleteIcon />}> Delete
        </Button>
        <TextSnippetIcon style={{padding: "5px", fontSize: "3rem"}} ></TextSnippetIcon>
      </CardActions>
    </Card>
  );
}