import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardMedia } from '@mui/material';

export default function CreateBibForm({createBib}) {
  const [bibName, setBibName] = useState([]);
 


  function onChangeSetName(event) {
    event.preventDefault();  
    setBibName(event.target.value)
   }



  return (
    <div style={{display: "flex", justifyContent: "flex-end" }}>
      <Box>
      <Card sx={{
           height: "100%",
           display: "flex",
           flexDirection: "column",
        minWidth: 200, maxWidth: 220, maxHeight: 270, height: 400}} >
      <CardContent>
      <CardMedia 
        component="img"
        height="130"
        width="220"
        image="https://picsum.photos/id/24/4855/1803.jpg"
        alt="uploaded img"/>

        <Typography variant="h5" component="div" >
        {bibName}
        </Typography>
      </CardContent>
      <CardActions  sx={{ mt: "auto" }}>
        <Button 
         variant="outlined" color="error" endIcon={<DeleteIcon />}> Delete
        </Button>
        <Button  variant="outlined" size="medium">Add element</Button>
      </CardActions>
    </Card>
    </Box>
  <div className="sidebar-nav">
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={createBib}>
      <div className="CreateBibFormBox">
      <TextField
          required
          id="name"
          label="Bib Name"
          onChange={onChangeSetName}
          name="name"
          
        />
        <TextField
          required
          id="upload"
          label="Image URL"
          name="upload"
        />
        <TextField
          required
          id="description"
          multiline
          rows={4}
          label="Description"
          name="description"
        />
        <Button  variant="outlined" size="medium"  type="submit">Create Bib</Button>
      </div>
    </Box>
    </div>
    </div>  
  );
}