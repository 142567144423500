import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';



const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
export default function QuoteCard({quote,  deleteOnClick}) {
  const navigate = useNavigate();
  
  return (
    <Card sx={{ 
      height: "100%",
      display: "flex",
      flexDirection: "column",
      minWidth: 220, maxWidth: 220, maxHeight: 400, height: 400}} >
      <CardContent>
      <Typography variant="h5" component="div">
         {quote.element.name}
        </Typography>
        <Typography variant="body2" component="div">
          {quote.quoteText}
        </Typography>
      </CardContent>
      <CardActions sx={{mt: "auto" }}>
        <Button onClick={() => deleteOnClick(quote.id)}
         variant="outlined" color="error" endIcon={<DeleteIcon />}> Delete
        </Button>
      </CardActions>
    </Card>
  );
}