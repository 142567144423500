/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBib = /* GraphQL */ `
  mutation CreateBib(
    $input: CreateBibInput!
    $condition: ModelBibConditionInput
  ) {
    createBib(input: $input, condition: $condition) {
      id
      name
      imageUrl
      description
      elements {
        items {
          id
          bibID
          name
          description
          type
          textData
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBib = /* GraphQL */ `
  mutation UpdateBib(
    $input: UpdateBibInput!
    $condition: ModelBibConditionInput
  ) {
    updateBib(input: $input, condition: $condition) {
      id
      name
      imageUrl
      description
      elements {
        items {
          id
          bibID
          name
          description
          type
          textData
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBib = /* GraphQL */ `
  mutation DeleteBib(
    $input: DeleteBibInput!
    $condition: ModelBibConditionInput
  ) {
    deleteBib(input: $input, condition: $condition) {
      id
      name
      imageUrl
      description
      elements {
        items {
          id
          bibID
          name
          description
          type
          textData
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createElement = /* GraphQL */ `
  mutation CreateElement(
    $input: CreateElementInput!
    $condition: ModelElementConditionInput
  ) {
    createElement(input: $input, condition: $condition) {
      id
      bibID
      name
      description
      type
      textData
      Bib {
        id
        name
        imageUrl
        description
        elements {
          nextToken
        }
        createdAt
        updatedAt
      }
      quotes {
        items {
          id
          elementID
          description
          quoteText
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateElement = /* GraphQL */ `
  mutation UpdateElement(
    $input: UpdateElementInput!
    $condition: ModelElementConditionInput
  ) {
    updateElement(input: $input, condition: $condition) {
      id
      bibID
      name
      description
      type
      textData
      Bib {
        id
        name
        imageUrl
        description
        elements {
          nextToken
        }
        createdAt
        updatedAt
      }
      quotes {
        items {
          id
          elementID
          description
          quoteText
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteElement = /* GraphQL */ `
  mutation DeleteElement(
    $input: DeleteElementInput!
    $condition: ModelElementConditionInput
  ) {
    deleteElement(input: $input, condition: $condition) {
      id
      bibID
      name
      description
      type
      textData
      Bib {
        id
        name
        imageUrl
        description
        elements {
          nextToken
        }
        createdAt
        updatedAt
      }
      quotes {
        items {
          id
          elementID
          description
          quoteText
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuote = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      id
      elementID
      description
      quoteText
      element {
        id
        bibID
        name
        description
        type
        textData
        Bib {
          id
          name
          imageUrl
          description
          createdAt
          updatedAt
        }
        quotes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote(
    $input: UpdateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    updateQuote(input: $input, condition: $condition) {
      id
      elementID
      description
      quoteText
      element {
        id
        bibID
        name
        description
        type
        textData
        Bib {
          id
          name
          imageUrl
          description
          createdAt
          updatedAt
        }
        quotes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote(
    $input: DeleteQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    deleteQuote(input: $input, condition: $condition) {
      id
      elementID
      description
      quoteText
      element {
        id
        bibID
        name
        description
        type
        textData
        Bib {
          id
          name
          imageUrl
          description
          createdAt
          updatedAt
        }
        quotes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
