import React, { useState, useEffect } from "react";
import Card from './Card.js';

import { API } from "aws-amplify";
import { listBibs } from "../../graphql/queries";
import {
        deleteBib as deleteBibMutation,
      } from "../../graphql/mutations";

function Bibs () {
  const [bibs, setBibs] = useState([]);
  
  useEffect(() => {
        fetchBibs();
      }, []);
    
      async function fetchBibs() {
        const apiData = await API.graphql({ query: listBibs });
        const bibsFromAPI = apiData.data.listBibs.items;
        setBibs(bibsFromAPI);
      }
  
      async function deleteBib(id) {
        const newBibs = bibs.filter((bib) => bib.id !== id);
        setBibs(newBibs);
        await API.graphql({
          query: deleteBibMutation,
          variables: { input: { id } },
        });
      }
      

    return (

      <div className='bib-container'>
       {bibs.map((bib) => (
        <div className='bib-container-child'              key={bib.id || bib.name} >
        <Card bib={bib}
             deleteOnClick={deleteBib}>

             </Card>
        </div>))} 
      </div>
    )
}

export default  Bibs;